.counter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #202124;
    font-size: .8rem;
    font-weight: 300;
}

.counter>p {
    position: absolute;
}