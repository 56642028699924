.flex {
    display: flex;
    gap: 10px;
}

.order {
    margin: 10px 0 30px;
}

.order>a,
.order>textarea {
    display: block;
    margin-bottom: 10px;
}

.order>a {
    max-width: 300px;
}