.sideMenu {
    font-size: 1.04rem;
    font-weight: 100;
    color: #202124;
    line-height: 20px;
}

.blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    pointer-events: none;
}

.sideMenu a {
    color: #202124;
}

.sideMenu a:hover {
    color: #0B6AFF;
}

a.active {
    color: #0B6AFF;
    font-weight: 200;
}

.sideMenu>div {
    margin: 0 0 25px 0;
}

.currentOffers {
    font-size: .85rem;
    color: #9AABBC;
    margin: 2px 0 0 0;
}

.currentOffers>span {
    margin: 0 0 0 20px;
}

.quit {
    cursor: pointer;
}

.promo {
    font-size: .9rem;
    line-height: 19px;
    line-height: 22px;
}

.promo a {
    color: #0B6AFF;
    font-weight: 200;
}

.promo>span {
    font-weight: 200;
}