.publicNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 65px;
    border-bottom: 1px solid #5F6368;
    color: #9AABBC;
    font-size: 1.05rem;
    font-weight: 200;
}

.flexCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexCenter>a {
    padding: 25px 35px;
    color: #9AABBC;
}

.flexCenter>a:last-of-type {
    border-bottom: 2px solid #8AB5F8;
    color: #8AB5F8;
}

.flexCenter>a:hover {
    color: #8AB5F8;
}

.flexCenter>img:last-of-type {
    margin-left: 35px;
}

@media screen and (max-width: 540px) {
    .publicNav {
        padding: 0 20px;
    }
    .flexCenter > a:nth-child(1),
    .flexCenter > a:nth-child(2) {
        display: none;
    }
}

@media screen and (max-width: 414px) {
    .flexCenter>a {
        padding: 25px 20px;
    }
}