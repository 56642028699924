.flex {
    display: flex;
    align-items: center;
}

.flex>a {
    margin-right: 14px;
}

.logoTxt>p {
    font-size: .65rem;
    color: #ADB7C2;
}

.logoTxt>p:first-of-type {
    font-size: .8rem;
    color: #fff;
    font-weight: 200;
}