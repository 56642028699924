.fullScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.error {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 20px;
    background-color: #ffb69a;
    color: #d4410a;
    font-size: .9rem;
    font-weight: 200;
    z-index: 9999;
}