.title {
    margin: -22px 0 25px;
}

.mini>h1 {
    font-size: 4.2rem;
}

.desc {
    margin: 15px 0 25px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin: 25px 0 15px;
}

.row>h2 {
    font-size: 1.7rem;
    font-weight: 100;
}

.notice {
    margin: 0 0 25px 0;
}

.total {
    font-size: .8rem;
    font-weight: 300;
}

@media screen and (max-width: 768px) {
    .mini>h1 {
        font-size: 2.9rem;
    }
}

@media screen and (max-width: 540px) {
    .title {
        margin: 30px 0 0 0;
    }
}

@media screen and (max-width: 375px) {
    .mini>h1 {
        font-size: 2.1rem;
    }
}