.title {
    margin: -22px 0 25px;
}

.mini>h1 {
    font-size: 4.2rem;
}

.desc {
    margin: 15px 0 25px;
}

@media screen and (max-width: 768px) {
    .mini>h1 {
        font-size: 2.9rem;
    }
}

@media screen and (max-width: 540px) {
    .title {
        margin: 30px 0 0 0;
    }
}

@media screen and (max-width: 375px) {
    .mini>h1 {
        font-size: 2.1rem;
    }
}