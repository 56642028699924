.options {
    display: flex;
    justify-content: space-between;
}

.options>div {
    cursor: pointer;
    width: 265px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    border: 2px solid #f3f5f7;
    border-radius: 4px;
}

.options>div.disabled {
    cursor: not-allowed;
}

.disabled .texts>p:first-of-type {
    color: #6f7989;
}

.blue {
    color: #0b6aff;
    font-weight: 200;
}

.optionCircle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 2px solid #D9DDE1;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.optionCircle>div {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #0B6AFF;
}

.texts {
    font-size: .68rem;
    font-weight: 100;
    color: #6A7486;
    line-height: 15px;
    max-width: 140px;
    pointer-events: none;
}

.texts>p:first-of-type {
    font-size: .8rem;
    font-weight: 300;
    color: #202124;
    margin-bottom: 10px;
}

@media screen and (max-width: 540px) {
    .options {
        display: block;
    }

    .options>div {
        width: 100%;
        margin-bottom: 10px;
    }
}