.notification {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 20px 30px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background-color: #F7FAFC;
    color: #202124;
    font-size: .9rem;
    font-weight: 100;
    line-height: 22px;
}

.notification>img {
    margin: 5px 10px 0 0;
}

.green {
    background-color: #f4fef4;
    color: #58a861;
    border-color: #cde6cf;
}