.offer {
    margin: 20px 0;
    border-radius: 23px;
    border: 1px solid #EBEBEB;
    padding: 30px;
}

.offer>h2 {
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 30px;
    max-width: 360px;
    margin: 0 0 20px 0;
}

.offer>h3,
.done>h3 {
    font-size: .9rem;
    font-weight: 200;
    margin: 25px 0 12px;
}

.offer>p {
    font-size: .87rem;
    font-weight: 100;
    line-height: 22px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 25px 0;
}

.buyer {
    display: flex;
    align-items: center;
}

.buyer>div:first-of-type {
    width: 26px;
    height: 26px;
    border-radius: 26px;
    color: #fff;
    font-size: .8rem;
    font-weight: 300;
    background-color: #EE675C;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.email {
    font-size: .9rem;
    font-weight: 200;
    color: #202124;
    margin: 0 0 0 10px;
}

.email>p:last-of-type {
    margin: 3px 0 0 0;
    font-size: .75rem;
    font-weight: 100;
    color: #6A7486;
}

.amount {
    display: flex;
    align-items: center;
}

.amount>p:first-of-type {
    font-size: .7rem;
    font-weight: 200;
    color: #0B6AFF;
}

.amount>p:last-of-type {
    font-size: .7rem;
    font-weight: 200;
    border-radius: 4px;
    background-color: #E3E8EE;
    color: #50576B;
    padding: 2px 20px 3px;
    margin: 0 0 0 10px;
}

.rows>div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 0 15px 0;
    white-space: nowrap;
}

.rows p,
.subline {
    font-size: .87rem;
    font-weight: 100;
    line-height: 22px;
}

p.summ {
    font-size: 1.5rem;
    font-weight: 100;
}

.red {
    color: #f94a3c;
}

.dotted {
    border: none;
    border-bottom: 1px dashed #E3E8EE;
    height: 1px;
    margin: 0 5px;
    flex-basis: 100%;
}

.copy {
    display: flex;
    align-items: center;
}

.copy>svg {
    margin: 0 10px 0 0;
    cursor: pointer;
}

.copy>svg:active g {
    fill: #0B6AFF;
}

.backlink>p:first-of-type {
    font-size: .87rem;
    width: 180px;
    line-height: initial;
}

.backlink>p:last-of-type {
    font-size: .75rem;
    font-weight: 100;
    color: #A0B0C0;
}

.buttons {
    display: flex;
    gap: 10px;
    margin: 10px 0 0 0;
}

.register .buttons>a {
    font-weight: 200;
    font-size: .85rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border-radius: 4px;
    flex-basis: 100%;
}

.register .buttons>a:first-of-type {
    color: #9AABBC;
    background-color: #F1F1F1;
}

.register .buttons>a:last-of-type {
    color: #fff;
    background-color: #48C966;
}

.done>button {
    margin: 10px 0 0 0;
}

@media screen and (max-width: 414px) {
    .register .buttons>a {
        flex-basis: initial;
    }
    .amount>p:last-of-type {
        display: none;
    }
    .buttons {
        flex-direction: column;
    }
}

@media screen and (max-width: 375px) {
    .offer {
        padding: 20px;
    }

    .backlink>p:first-of-type {
        width: 130px;
    }
}