.register {
    margin-bottom: 40px;
}

.title {
    margin: 35px 0 25px;
}

.desc {
    margin: 15px 0 25px;
}

.options {
    margin: 25px 0;
}

.email {
    margin: 25px 0 30px;
}

.notify {
    margin: 20px 0;
}

.email .notification {
    margin: 10px 0 0 0;
}

.password {
    margin: 10px 0;
}

.submit {
    margin: 10px 0;
}

.terms {
    margin: 10px 0 20px;
}

.haveAcc {
    font-weight: 200;
    font-size: 1.1rem;
    margin: 40px 0 15px;
}