@font-face {
  font-family: 'Aeonik';
  src: url("./fonts/aeonik/Aeonik-Regular.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: 'Aeonik';
  src: url("./fonts/aeonik/Aeonik-Medium.otf") format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: 'Aeonik';
  src: url("./fonts/aeonik/Aeonik-Bold.otf") format("opentype");
  font-weight: 300;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Aeonik';
  font-weight: 100;
  font-size: .95rem;
  color: #202124;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none
}

input[type="text"],
input[type="password"],
input[type="email"] {
  appearance: none;
  font-size: .95rem;
  font-weight: 100;
  font-family: 'Aeonik', sans-serif;
  outline: none;
  color: #202124;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  transition: box-shadow .2s ease;
  border: 1px solid #D9DDE1;
  border-radius: 4px;
  padding: 10px 15px 10px 32px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input::placeholder {
  font-family: 'Aeonik', sans-serif;
  font-weight: 100;
  font-size: .95rem;
  color: #A5ADBB;
}

input:focus {
  outline: none;
  border: 1px solid #C8D0D9;
  box-shadow: 0 0 0 3px #F1F1F1;
}

input:disabled,
input:read-only {
  background: #EFF5F9;
  color: #6A7486;
}

select {
  appearance: none;
  font-size: 1rem;
  font-family: 'Aeonik', sans-serif;
  background: #fff;
  outline: none;
  color: #3A3A3A;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 1px 4px 1px #00000015;
  transition: box-shadow .2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select:focus {
  outline: none;
  box-shadow: 0 0 0 3px #C6E2FF;
  border: 1px solid #A5CFFA;
}

img {
  display: block;
}

img,
ul,
ol,
li,
div,
a,
p,
span,
blockquote,
textarea,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

ul {
  list-style: none
}

textarea {
  appearance: none;
  display: block;
  resize: none;
  outline: none;
  font-weight: 100;
  font-size: .95rem;
  font-family: 'Aeonik', sans-serif;
  width: 100%;
  color: #202124;
  transition: box-shadow .2s ease;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #D9DDE1;
  border-radius: 4px;
  padding: 10px 15px 10px 32px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder {
  font-family: 'Aeonik', sans-serif;
  color: #A5ADBB;
  font-weight: 100;
  font-size: .95rem;
}

textarea::placeholder {
  color: #A5ADBB;
}

textarea:focus {
  outline: none;
  border: 1px solid #C8D0D9;
  box-shadow: 0 0 0 3px #F1F1F1;
}

button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Aeonik', sans-serif;
  font-size: .85rem;
  font-weight: 200;
  outline: none;
  color: #fff;
  cursor: pointer;
  background-color: #0B6AFF;
  width: 100%;
  height: 38px;
  border: none;
  border-radius: 4px;
}

button:focus {
  box-shadow: 0 0 0 3px #C6E2FF;
}

a {
  color: #0B6AFF;
  outline: none;
  text-decoration: none;
}

a:hover {
  color: #1065ec;
}

h1 {
  font-size: 5.1rem;
  font-weight: 100;
}

.noselect {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hrz-scroll {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.hrz-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 540px) {
  h1 {
    font-size: 2.9rem;
  }
}