.dark {
    width: 100%;
    height: 100vh;
    background-color: #202124;
    overflow: auto;
}

.centered {
    margin: 50px auto;
    max-width: 610px;
    padding: 0 20px;
}

.publisher {
    display: flex;
    padding: 45px 65px;
}

.left {
    flex-grow: 1;
    max-width: 205px;
    margin-right: 10px;
}

.right {
    flex-grow: 1;
    max-width: 570px;
}

.exitWrap {
    margin: 20px 0;
}

.exitWrap>p {
    border-radius: 4px;
    text-align: center;
    padding: 10px;
    color: #9aabbc;
    background-color: #f1f1f1;
}

@media screen and (max-width: 1024px) {
    .publisher {
        padding: 45px 20px;
    }
}

@media screen and (max-width: 540px) {
    .publisher {
        padding: 20px;
    }

    .left {
        display: none;
    }
}