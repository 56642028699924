.centered {
    padding: 0 20px;
    margin: 60px auto 0;
    max-width: 610px;
}

.centered>h1 {
    margin: 20px 0 15px;
    font-size: 5.1rem;
    font-weight: 100;
    color: #fff;
}

.centered>p {
    font-size: .85rem;
    color: #fff;
    max-width: 415px;
    line-height: 20px;
    margin-bottom: 25px;
}

.flex {
    display: flex;
}

.flex>a {
    font-size: 1rem;
    font-weight: 200;
    color: #9AABBC;
}

.flex>a:hover {
    color: #1B77FF;
}

.flex>a:first-of-type {
    margin: 0 30px 0 0;
    color: #1B77FF;
}

.mozaika {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: -60px;
    pointer-events: none;
}

.subscribe {
    margin: 75px auto 40px;
    color: #3C4357;
    font-size: .95rem;
    font-weight: 200;
    max-width: 610px;
    padding: 0 20px;
}

.subscribe input {
    background-color: #444956;
    border: none;
    margin: 10px 0;
}

.subscribe input::placeholder {
    color: #6A7486;
}

.subscribe input:focus {
    box-shadow: 0 0 0 3px #29292c;
  }

.subscribe p:last-of-type {
    font-size: .7rem;
    font-weight: 100;
}

.support {
    max-width: 610px;
    padding: 0 20px;
    margin: 40px auto;
    color: #3C4357;
    font-size: .9rem;
    font-weight: 200;
}

.support>div {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.support>div>img {
    margin: 0 10px 0 0;
}

.support>div a {
    margin: 0 0 0 5px;
}

@media screen and (max-width: 768px) {
    .mozaika img {
        height: 150px;
    }

    .mozaika {
        max-width: 610px;
        margin: 40px auto;
        padding: 0 20px;
    }
}

@media screen and (max-width: 540px) {
    .centered>h1 {
        font-size: 2.9rem;
    }
}

@media screen and (max-width: 414px) {
    .mozaika img {
        height: 100px;
    }
}