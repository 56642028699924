.topMenu {
    padding: 6px 7px;
    background-color: #F1F1F1;
    border-radius: 12px;
    display: none;
    margin-bottom: 30px;
    overflow-x: auto;
}

.topMenu a,
.topMenu p {
    font-size: .9rem;
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 150px;
    cursor: pointer;
    height: 36px;
    border-radius: 7px;
    background-color: #F1F1F1;
    color: #9D9D9D;
    white-space: nowrap;
}

a.active {
    color: #0B6AFF;
    background-color: #E8E8E8;
}

@media screen and (max-width: 540px) {
    .topMenu {
        display: flex;
    }
}