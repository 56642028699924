.flex {
    display: flex;
}

.types a {
    display: flex;
    flex: 0 0 120px;
    height: 36px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #FFF;
    color: #9AABBC;
    font-size: .9rem;
    font-weight: 200;
}

a.active {
    background-color: #F1F1F1;
    color: #202124;
}

.title {
    margin: -22px 0 25px;
}

.promo {
    line-height: 22px;
}

.promo>span {
    font-weight: 200;
}

.mini>h1 {
    font-size: 4.2rem;
}

.desc {
    margin: 15px 0 25px;
}

.email {
    margin: 25px 0 10px;
}

.submit {
    margin: 10px 0;
}

.offersList {
    margin: 20px 0 0 0;
}

@media screen and (max-width: 768px) {
    .mini>h1 {
        font-size: 2.9rem;
    }
}

@media screen and (min-width: 768px) {
    .promo {
        display: none;
    }
}

@media screen and (max-width: 540px) {
    .title {
        margin: 30px 0 0 0;
    }
}

@media screen and (max-width: 375px) {
    .mini>h1 {
        font-size: 2.1rem;
    }
}