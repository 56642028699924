.publisherNav {
    padding: 18px 65px;
    border-bottom: 1px solid #EBEBEB;
}

.publisherNav,
.right,
.balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left,
.middle,
.right {
    flex-grow: 1;
}

.left {
    max-width: 205px;
    margin-right: 10px;
}

.right {
    max-width: 340px;
}

.blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    pointer-events: none;
}

.promo {
    color: #9AABBC;
}

.promo>span {
    color: #202124;
    font-weight: 200;
}

.registeredDomain {
    color: #9AABBC;
}

.balance>div {
    margin: 0 25px 0 0;
}

.balanceTitle {
    font-size: 1.1rem;
    color: #9AABBC;
}

.transactions {
    font-size: .8rem;
}

.amount {
    font-size: 1.45rem;
    margin: 0 10px 0 0;
}

.navIcons {
    display: flex;
    align-items: center;
}

.navIcons>div:first-of-type {
    margin: 0 25px 0 0;
}

@media screen and (max-width: 1024px) {
    .publisherNav {
        padding: 18px 20px;
    }
}

@media screen and (max-width: 768px) {
    .middle>p {
        display: none;
    }
    .right {
        max-width: 300px;
    }
    .promo {
        display: none;
    }
}

@media screen and (max-width: 414px) {
    .transactions {
        display: none;
    }
}

@media screen and (max-width: 375px) {
    .balanceTitle {
        display: none;
    }
}