.flex {
    display: flex;
    align-items: center;
    font-size: .8rem;
    font-weight: 100;
}

.flex>input {
    margin: 0 8px 0 0;
}

.flex>p {
    max-width: 200px;
}